import { Policy } from './policies.model';

export function createPolicy(initialValues: Partial<Policy> = {}): Policy {
  return {
    firstName: undefined,
    fullName: undefined,
    address: undefined,
    policyType: undefined,
    effectiveDate: undefined,
    premium: undefined,
    redirectTo: undefined,
    discounts: [],
    primaryCoverages: [],
    deductibles: [],
    endorsements: [],
    otherPolicyFeatures: [],
    fees: [],
    ...initialValues,
  };
}
